// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--+znxk";
export var breakme = "styles-module--breakme--HWs+x";
export var content = "styles-module--content--uMkDz";
export var header = "styles-module--header--371NX";
export var holder = "styles-module--holder--t1m4z";
export var howHolder = "styles-module--howHolder--zSfJD";
export var image = "styles-module--image--2nGIQ";
export var kaynakca = "styles-module--kaynakca--NaBPW";
export var kidsHolder = "styles-module--kidsHolder--SBAUx";
export var listHolder = "styles-module--listHolder--EJhsh";
export var productHolder = "styles-module--productHolder--Ln1gx";
export var rightCoughHolder = "styles-module--rightCoughHolder--7Zx8v";
export var typesHolder = "styles-module--typesHolder--4TecE";
export var whatHolder = "styles-module--whatHolder--Mu5lQ";
export var whyHolder = "styles-module--whyHolder--tpGcl";